/**
 *
 * Getters
 *
 */
export const getters = {
  // Auth (managed by Nuxt)
  isAuthenticated(state) {
    return state.auth.loggedIn;
  },

  user(state) {
    return state.auth.user;
  },

  /**
   * Returns a string with the role
   */
  userRole(state) {
    return state.auth?.user?.role?.name;
  },

  isUserRoleExpert(state, getters) {
    return getters.userRole?.toLowerCase() === 'expert';
  },

  computedName(state) {
    if (!state.auth.loggedIn) {
      return null;
    }
    return state.auth.user.firstname
      ? `${state.auth.user.firstname} ${state.auth.user.lastname}`
      : state.auth.user.email;
  }
};

/**
 *
 * Mutation
 *
 */
export const mutations = {
  updateUser(state, value) {
    state.auth.user = { ...state.auth.user, ...value };
  }
};

/**
 *
 * Actions
 *
 */
export const actions = {
  async nuxtServerInit({ state, dispatch, commit }, { req, res, route }) {},

  /**
   * Update a given address for the current user
   */
  async updateUser({ commit }, payload) {
    try {
      const { id } = payload;
      if (!id) {
        return Promise.reject(new Error('user.id should be defined'));
      }
      const updated = await this.$axios.$put(`users/${id}`, payload);
      commit('updateUser', payload);
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
};
