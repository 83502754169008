import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5050c262 = () => interopDefault(import('../pages/bookings/index.vue' /* webpackChunkName: "pages/bookings/index" */))
const _61e86d92 = () => interopDefault(import('../pages/experts/index.vue' /* webpackChunkName: "pages/experts/index" */))
const _a0fc7fae = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _7fc63c52 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4d2ed1f4 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _38b42934 = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _bad19ffe = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _7f45b200 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _f4f2ef1e = () => interopDefault(import('../pages/about/privacy.vue' /* webpackChunkName: "pages/about/privacy" */))
const _ccd574a8 = () => interopDefault(import('../pages/about/terms-of-service.vue' /* webpackChunkName: "pages/about/terms-of-service" */))
const _0f0162c6 = () => interopDefault(import('../pages/experts/signup/index.vue' /* webpackChunkName: "pages/experts/signup/index" */))
const _a469c23e = () => interopDefault(import('../pages/help/_slug.vue' /* webpackChunkName: "pages/help/_slug" */))
const _67d4d83b = () => interopDefault(import('../pages/me/_slug/index.vue' /* webpackChunkName: "pages/me/_slug/index" */))
const _7d357aae = () => interopDefault(import('../pages/room/_id/index.vue' /* webpackChunkName: "pages/room/_id/index" */))
const _0f94c73d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _309a1c23 = () => interopDefault(import('../pages/_username/index.vue' /* webpackChunkName: "pages/_username/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bookings",
    component: _5050c262,
    name: "bookings"
  }, {
    path: "/experts",
    component: _61e86d92,
    name: "experts"
  }, {
    path: "/help",
    component: _a0fc7fae,
    name: "help"
  }, {
    path: "/login",
    component: _7fc63c52,
    name: "login"
  }, {
    path: "/messages",
    component: _4d2ed1f4,
    name: "messages"
  }, {
    path: "/pricing",
    component: _38b42934,
    name: "pricing"
  }, {
    path: "/reset-password",
    component: _bad19ffe,
    name: "reset-password"
  }, {
    path: "/signup",
    component: _7f45b200,
    name: "signup"
  }, {
    path: "/about/privacy",
    component: _f4f2ef1e,
    name: "about-privacy"
  }, {
    path: "/about/terms-of-service",
    component: _ccd574a8,
    name: "about-terms-of-service"
  }, {
    path: "/experts/signup",
    component: _0f0162c6,
    name: "experts-signup"
  }, {
    path: "/help/:slug",
    component: _a469c23e,
    name: "help-slug"
  }, {
    path: "/me/:slug?",
    component: _67d4d83b,
    name: "me-slug"
  }, {
    path: "/room/:id?",
    component: _7d357aae,
    name: "room-id"
  }, {
    path: "/",
    component: _0f94c73d,
    name: "index"
  }, {
    path: "/:username",
    component: _309a1c23,
    name: "username"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
