import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
const options = {"environment":"production","appVersion":"1.0.0","enabledReleaseStages":["production"],"apiKey":"5481bd52b52e489f0285c89f94e07480"}
options.plugins = [new BugsnagPluginVue(Vue)]

let bugsnagClient

export default function ({ app }, inject) {
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start(options)
  } else {
    bugsnagClient = Bugsnag._client
  }

  inject('bugsnag', bugsnagClient)
}
