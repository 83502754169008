<template>
  <div>
    <NavigationBar />
    <nuxt />
    <portal-target name="modals"></portal-target>
  </div>
</template>

<script>
import NavigationBar from '~/components/NavigationBar';

export default {
  components: {
    NavigationBar
  }
};
</script>

<style>
/* general class tranition */
.transition-fade-enter-active,
.transition-fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.transition-fade-enter,
.transition-fade-leave-active {
  opacity: 0;
}

.origin-top-right {
  transform-origin: top right;
}
.transition-all {
  transition-property: all;
}
.transition-fastest {
  transition-duration: 50ms;
}
.transition-faster {
  transition-duration: 100ms;
}
.transition-fast {
  transition-duration: 150ms;
}
.transition-medium {
  transition-duration: 200ms;
}
.ease-out-quad {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ease-in-quad {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.scale-70 {
  transform: scale(0.7);
}
.scale-100 {
  transform: scale(1);
}
</style>
