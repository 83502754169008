export default (context) => {
  if (!process.browser || process.env.NODE_ENV !== 'production') {
    return;
  }
  !(function(e, t, n, s, u, a) {
    e.twq ||
      ((s = e.twq = function() {
        s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
      }),
      (s.version = '1.1'),
      (s.queue = []),
      (u = t.createElement(n)),
      (u.async = !0),
      (u.src = '//static.ads-twitter.com/uwt.js'),
      (a = t.getElementsByTagName(n)[0]),
      a.parentNode.insertBefore(u, a));
  })(window, document, 'script');
  // Insert Twitter Pixel ID and Standard Event data below
  /* eslint-disable */
  twq('init', 'o4a0q');
  twq('track', 'PageView');
};
