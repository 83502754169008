<template>
  <nav
    id="global-nav"
    class="navbar-container"
    :class="{ open: menuOpen, 'logged-in': isAuthenticated }"
    @keydown.escape="closeAll"
  >
    <div id="global-nav-content" class="container">
      <!-- navigation -->
      <div id="navbar-header">
        <!-- logo -->
        <div id="navbar-logo" class="h-full flex items-center md:mx-0 md:-mb-1">
          <nuxt-link
            class="flex-shrink-0 cursor-pointer"
            :to="{ name: 'index' }"
            @click.native="menuOpen = false"
          >
            <svg
              width="112"
              height="16"
              viewBox="0 0 182 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.899902 13.2999C0.899902 20.6799 6.6299 25.5999 13.9799 25.5999C17.4599 25.5999 21.0599 24.7299 24.1799 21.6999V15.9399C24.1799 13.8099 24.6599 13.1799 26.6699 13.1799V12.2799H12.5699V13.1799C15.9599 13.1799 16.9799 14.1099 16.9799 15.9399V22.8699C16.0499 23.5599 15.2999 23.7999 14.1299 23.7999C9.6299 23.7999 8.6999 19.7199 8.6999 13.2999C8.6999 6.8799 10.3799 2.1999 15.4199 2.1999C18.9299 2.1999 21.1199 4.4199 21.6299 8.9499H22.5299L22.0799 0.699902H21.1799C21.1799 1.4199 21.0599 1.5999 20.6699 1.5999C19.9199 1.5999 18.0299 0.399902 14.1299 0.399902C6.4799 0.399902 0.899902 5.9199 0.899902 13.2999Z"
                fill="#292D3D"
              />
              <path
                d="M36.1023 25.5999C41.4123 25.5999 44.9223 22.1199 44.9223 16.8999C44.9223 11.6799 41.4123 8.1999 36.1023 8.1999C30.7923 8.1999 27.2823 11.6799 27.2823 16.8999C27.2823 22.1199 30.7923 25.5999 36.1023 25.5999ZM36.7023 23.7699C34.9023 23.8899 34.3623 22.4499 33.8823 17.0799C33.4323 11.7399 33.7023 10.1799 35.5023 10.0299C37.3023 9.8799 37.8423 11.3499 38.3223 16.7199C38.7723 22.0599 38.5023 23.6499 36.7023 23.7699Z"
                fill="#292D3D"
              />
              <path
                d="M54.9554 25.5999C60.2654 25.5999 63.7754 22.1199 63.7754 16.8999C63.7754 11.6799 60.2654 8.1999 54.9554 8.1999C49.6454 8.1999 46.1354 11.6799 46.1354 16.8999C46.1354 22.1199 49.6454 25.5999 54.9554 25.5999ZM55.5554 23.7699C53.7554 23.8899 53.2154 22.4499 52.7354 17.0799C52.2854 11.7399 52.5554 10.1799 54.3554 10.0299C56.1554 9.8799 56.6954 11.3499 57.1754 16.7199C57.6254 22.0599 57.3554 23.6499 55.5554 23.7699Z"
                fill="#292D3D"
              />
              <path
                d="M84.3686 20.9499C82.5986 21.8799 82.5986 21.3699 82.5986 19.2399V0.999904L81.9986 0.399902L74.7086 4.2699L75.1286 5.0499C76.8986 4.1199 76.8986 4.6299 76.8986 6.7599V9.9099H76.8386C76.1186 9.1599 74.7086 8.1999 72.5186 8.1999C68.6786 8.1999 64.9886 11.2299 64.9886 17.5299C64.9886 22.9299 67.8386 25.5999 71.3186 25.5999C74.0186 25.5999 75.6086 24.2799 76.7786 22.2099H76.8986V24.9999L77.4986 25.5999L84.7886 21.7299L84.3686 20.9499ZM76.8986 20.4099C76.0586 21.7599 75.0986 22.8999 74.0486 22.8999C72.0686 22.8999 71.5886 19.4199 71.5886 16.0599C71.5886 12.2499 72.1886 9.9999 74.1686 9.9999C75.5186 9.9999 76.8986 11.3499 76.8986 15.3099V20.4099Z"
                fill="#292D3D"
              />
              <path
                d="M101.497 13.8999L97.8374 0.999904H85.3874V1.8999C87.9974 1.8999 88.7774 2.8299 88.7774 4.6599V21.3399C88.7774 23.1699 87.9974 24.0999 85.3874 24.0999V24.9999H93.9674V24.0999C91.3574 24.0999 90.5774 23.1699 90.5774 21.3399L90.5174 3.0399H90.6074L97.0874 25.6899H100.087L106.267 2.7399H106.357V21.3399C106.357 23.1699 105.577 24.0999 102.967 24.0999V24.9999H116.947V24.0999C114.337 24.0999 113.557 23.1699 113.557 21.3399V4.6599C113.557 2.8299 114.337 1.8999 116.947 1.8999V0.999904H105.007L101.587 13.8999H101.497Z"
                fill="#292D3D"
              />
              <path
                d="M122.738 5.7999C124.238 5.7999 125.438 4.5699 125.438 3.0999C125.438 1.5999 124.238 0.399902 122.738 0.399902C121.268 0.399902 120.038 1.5999 120.038 3.0999C120.038 4.5699 121.268 5.7999 122.738 5.7999ZM125.438 21.3399V8.7999L124.838 8.1999L117.308 12.1899L117.728 12.9699C119.678 11.9499 119.738 12.4299 119.738 14.5599V21.3399C119.738 23.4699 119.258 24.0999 117.248 24.0999V24.9999H127.928V24.0999C125.918 24.0999 125.438 23.4699 125.438 21.3399Z"
                fill="#292D3D"
              />
              <path
                d="M136.147 8.7999L135.547 8.1999L128.017 12.1899L128.437 12.9699C130.387 11.9499 130.447 12.4299 130.447 14.5599V21.3399C130.447 23.4699 129.967 24.0999 127.957 24.0999V24.9999H138.127V24.0999C136.537 24.0999 136.147 23.4699 136.147 21.3399V13.8399C137.347 12.2199 138.547 11.4999 139.477 11.4999C140.497 11.4999 140.767 12.2199 140.767 13.2699V21.3399C140.767 23.4699 140.377 24.0999 138.787 24.0999V24.9999H148.957V24.0999C146.947 24.0999 146.467 23.4699 146.467 21.3399V13.2699C146.467 9.5499 144.907 8.1999 142.567 8.1999C139.687 8.1999 137.587 10.1799 136.237 12.0399H136.147V8.7999Z"
                fill="#292D3D"
              />
              <path
                d="M168.043 20.9499C166.273 21.8799 166.273 21.3699 166.273 19.2399V0.999904L165.673 0.399902L158.383 4.2699L158.803 5.0499C160.573 4.1199 160.573 4.6299 160.573 6.7599V9.9099H160.513C159.793 9.1599 158.383 8.1999 156.193 8.1999C152.353 8.1999 148.663 11.2299 148.663 17.5299C148.663 22.9299 151.513 25.5999 154.993 25.5999C157.693 25.5999 159.283 24.2799 160.453 22.2099H160.573V24.9999L161.173 25.5999L168.463 21.7299L168.043 20.9499ZM160.573 20.4099C159.733 21.7599 158.773 22.8999 157.723 22.8999C155.743 22.8999 155.263 19.4199 155.263 16.0599C155.263 12.2499 155.863 9.9999 157.843 9.9999C159.193 9.9999 160.573 11.3499 160.573 15.3099V20.4099Z"
                fill="#292D3D"
              />
              <path
                d="M169.376 25.2999H170.276C170.276 24.6999 170.366 24.3999 170.846 24.3999C171.566 24.3999 172.886 25.5999 176.006 25.5999C178.946 25.5999 181.646 24.1299 181.646 20.5899C181.646 17.5899 179.606 16.1799 176.486 13.6299C174.236 11.7999 173.576 11.4099 173.576 10.7499C173.576 10.2099 174.086 9.9999 174.806 9.9999C177.026 9.9999 178.946 11.6799 179.546 13.8099H180.446V8.4999H179.546C179.546 9.0999 179.546 9.3999 179.096 9.3999C178.496 9.3999 177.296 8.1999 174.686 8.1999C171.416 8.1999 169.376 9.9099 169.376 12.7599C169.376 15.7299 171.386 17.0499 174.146 19.2999C176.576 21.2799 177.446 21.9099 177.446 22.7799C177.446 23.5299 176.816 23.7999 175.856 23.7999C172.886 23.7999 170.876 21.5199 170.276 18.9099H169.376V25.2999Z"
                fill="#292D3D"
              />
            </svg>
          </nuxt-link>
        </div>
        <!-- links desktop - non auth -->
        <div
          v-if="!isAuthenticated"
          class="hidden md:flex justify-center items-center ml-auto"
        >
          <nuxt-link
            class="button-link mr-12 font-normal"
            :to="{ name: 'experts' }"
          >
            Experts
          </nuxt-link>
          <nuxt-link
            :to="{ name: 'login' }"
            class="button-link mr-12 font-normal"
          >
            Sign in
          </nuxt-link>
          <!-- CTA -->
          <button
            class="button-outline text-black border-gray-400 hover:bg-transparent focus:bg-transparent"
            aria-label="Join beta"
            @click="onSignupClicked"
          >
            <span class="mr-2">Join Beta</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="14"
              height="14"
              class="fill-current white"
            >
              <path
                class="heroicon-ui"
                d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
              />
            </svg>
          </button>
        </div>

        <!-- Menu mobile -->
        <div v-if="!isAuthenticated" id="navbar-button-menu">
          <!-- Mobile menu button -->
          <button
            class="button-menu"
            aria-label="menu button"
            :class="{ 'is-open': menuOpen }"
            @click="menuOpen = !menuOpen"
          >
            <span class="menu-icon menu-icon-top"
              ><span class="menu-icon-line menu-icon-line-top"
            /></span>
            <span class="menu-icon menu-icon-bottom"
              ><span class="menu-icon-line menu-icon-line-bottom"
            /></span>
          </button>
        </div>

        <!-- profil + dropdown -->
        <div v-if="isAuthenticated" id="navbar-user-button">
          <!-- <nuxt-link
            :to="{ name: 'messages' }"
            class="button-link text-sm mr-12 text-gray-600 hover:text-gray-700 hidden md:block"
            @click.native="closeAll"
          >
            Messages
          </nuxt-link> -->
          <nuxt-link
            :to="{ name: 'bookings' }"
            class="button-link text-sm mr-12 text-gray-600 hover:text-gray-700 hidden md:block"
            @click.native="closeAll"
          >
            Bookings
          </nuxt-link>

          <div class="relative">
            <div
              v-if="dropdownOpen"
              class="fixed inset-0"
              @click="dropdownOpen = false"
            ></div>
            <button
              class="relative flex items-center focus:outline-none"
              aria-label="Menu"
              @click="dropdownOpen = !dropdownOpen"
            >
              <img
                v-if="user.avatar && user.avatar[0]"
                loading="lazy"
                :src="baseUrl + user.avatar[0].url"
                class="avatar-size rounded-full bg-gray-200 object-cover"
              />
              <div
                v-else
                class="avatar-size flex items-center justify-center rounded-full bg-blured border hover:bg-gray-100"
                :class="{ 'bg-gray-100': dropdownOpen }"
              >
                <span
                  class="font-bold text-xl leading-none text-gray-800 -mb-px"
                >
                  {{ computedName[0].toUpperCase() }}
                </span>
              </div>
            </button>
            <transition
              enter-active-class="transition-all transition-fastest ease-out-quad"
              leave-active-class="transition-all transition-faster ease-in-quad"
              enter-class="transform opacity-0 scale-75 skew-x-6"
              enter-to-class="transform opacity-100 scale-100 skew-x-0"
              leave-class="transform opacity-100 scale-100 skew-x-0"
              leave-to-class="transform opacity-0 scale-75 skew-x-6"
            >
              <div
                v-if="dropdownOpen"
                class="origin-top-right absolute right-0 mt-2 w-40 bg-white rounded-lg border shadow-md py-2"
              >
                <ul>
                  <li>
                    <nuxt-link
                      :to="{
                        name: 'me-slug',
                        params: { slug: 'profile', username: user.username }
                      }"
                      class="block px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100"
                      @click.native="closeAll"
                    >
                      Profile
                    </nuxt-link>
                  </li>
                  <li class="md:hidden">
                    <!-- <nuxt-link
                      :to="{ name: 'messages' }"
                      class="block px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200"
                      @click.native="closeAll"
                    >
                      Messages
                    </nuxt-link> -->
                  </li>
                  <li>
                    <nuxt-link
                      :to="{ name: 'bookings' }"
                      class="block px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100"
                      @click.native="closeAll"
                    >
                      Bookings
                    </nuxt-link>
                  </li>
                  <li>
                    <div
                      class="block cursor-pointer w-full px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100"
                      @click="logout"
                    >
                      Sign out
                    </div>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <!-- menu -->
      <ul class="nav-list">
        <!-- Visitor -->
        <li v-if="!isAuthenticated" class="nav-list-item">
          <nuxt-link
            class="button-link mr-12 font-normal"
            :to="{ name: 'experts' }"
            @click.native="menuOpen = false"
          >
            Experts
          </nuxt-link>
        </li>
        <!-- <li v-if="!isAuthenticated" class="nav-list-item">
          <nuxt-link
            :to="{ name: 'pricing' }"
            class="button-link mr-12 font-normal"
            @click.native="menuOpen = false"
          >
            Pricing
          </nuxt-link>
        </li> -->
        <li v-if="!isAuthenticated" class="nav-list-item">
          <nuxt-link
            :to="{ name: 'login' }"
            class="button-link mr-12 font-normal"
            @click.native="menuOpen = false"
          >
            Sign in
          </nuxt-link>
        </li>
        <li v-if="!isAuthenticated" class="mt-12">
          <button
            class="button-outline text-black border-gray-400 hover:bg-transparent focus:bg-transparent"
            aria-label="Join beta"
            @click="onSignupClicked"
          >
            <span class="mr-2">Join Beta</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="14"
              height="14"
              class="fill-current white"
            >
              <path
                class="heroicon-ui"
                d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
              />
            </svg>
          </button>
        </li>
        <!-- <li class="nav-list-item">Item 4</li> -->
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    dropdownOpen: false,
    menuOpen: false,
    baseUrl: process.env.baseUrl
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      isAuthenticated: 'isAuthenticated',
      computedName: 'computedName'
    })
  },

  methods: {
    closeAll() {
      this.dropdownOpen = false;
      if (this.menuOpen) {
        this.menuOpen = false;
      }
    },

    onSignupClicked() {
      this.menuOpen = false;
      this.$router.push({ name: 'experts-signup' });
    },

    async logout() {
      try {
        this.$auth.setToken(false);
        this.$auth.setRefreshToken(false);
        this.$axios.setHeader('Authorization', false);
        this.dropdownOpen = false;
        await this.$auth.logout();
      } catch (error) {}
    }
  }
};
</script>

<style lang="postcss" scoped>
#global-nav {
  @apply relative top-0 inset-x-0 block m-0 p-0 w-full h-20 select-none overflow-y-hidden;
  z-index: 49;
  min-width: 320px;
  max-height: none;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: none;
  transition: background 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1),
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);

  &.logged-in {
    @apply overflow-visible;
    #navbar-header {
      @apply overflow-visible;
    }
  }

  &.open {
    height: 400px;
    background: white;
    transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
      height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);

    .nav-list {
      visibility: visible;
      transition-delay: 0s;
    }

    .nav-list-item {
      opacity: 1;
      pointer-events: auto;
      transform: none;
      transition: opacity 0.3091s cubic-bezier(0.32, 0.08, 0.24, 1) 0.03s,
        transform 0.3455s cubic-bezier(0.32, 0.08, 0.24, 1) 0.02s;
    }
  }

  #global-nav-content {
    position: absolute;
    margin: 0 auto;
    z-index: 2;
    padding: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: none;

    @screen md {
      @apply max-w-3xl;
      position: relative;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @screen lg {
      @apply max-w-5xl;
    }
    @screen xl {
      max-width: 80rem;
    }
  }

  #navbar-header {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 48px; */
    height: 80px;
    overflow: hidden;
    display: block;

    @screen md {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      overflow: visible;
      justify-content: space-between;
    }
  }

  #navbar-logo {
    position: absolute;
    width: 112px;
    top: 0;
    left: 50%;
    margin-left: -56px;
    text-align: center;
    z-index: 1;

    @screen md {
      position: relative;
      left: 0%;
      margin-left: 0px;
    }
  }

  /** Menu button for mobile */
  #navbar-button-menu {
    position: absolute;
    right: 0;
    z-index: 2;
    top: 20px;

    @screen md {
      @apply hidden;
    }
    .button-menu {
      @apply relative h-10 w-10 rounded;
      &:focus {
        @apply outline-none;
      }
      .menu-icon {
        position: absolute;
        z-index: 3;
        top: 5px;
        left: 5px;
        width: 30px;
        height: 30px;
        opacity: 0.8;
        transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
        -webkit-tap-highlight-color: transparent;

        &:hover {
          opacity: 1;
        }
      }
      .menu-icon-top {
        transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
        transform: none;
        z-index: 4;
      }
      .menu-icon-bottom {
        transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
        transform: none;
      }
      .menu-icon-line {
        @apply bg-black block;
        background-color: black;
        width: 17px;
        height: 1px;
        border-radius: 0.5px;
        position: absolute;
        left: 7px;
        z-index: 1;
      }
      .menu-icon-line-top {
        transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84)
          0.1008s;
        top: 14px;
        transform: translateY(-3px);
      }
      .menu-icon-line-bottom {
        transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84)
          0.1008s;
        bottom: 14px;
        transform: translateY(3px);
      }
      &.is-open {
        .menu-icon-top {
          transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96)
            0.1008s;
          transform: rotate(45deg);
        }
        .menu-icon-bottom {
          transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96)
            0.1008s;
          transform: rotate(-45deg);
        }
        .menu-icon-line-top {
          transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
          transform: none;
        }
        .menu-icon-line-bottom {
          transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
          transform: none;
        }
      }
    }
  }

  #navbar-user-button {
    @apply absolute items-center flex justify-center ml-auto;
    @screen md {
      @apply relative;
    }
    top: 21px;
    right: 1rem;

    @screen md {
      top: auto;
    }
  }

  .nav-list {
    cursor: default;
    margin: 0 -10px;
    width: auto;
    height: 80px;
    display: flex;
    justify-content: space-between;
    user-select: none;
    display: block;
    position: absolute;
    z-index: 1;
    top: 3.52941em;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 664px;
    margin: 0 auto;
    padding: 45px 40px 8px;
    height: auto;
    box-sizing: border-box;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    transition: visibility 0s linear 1s;
  }
  .nav-list-item {
    @apply flex flex-col flex-no-wrap justify-center relative h-12 w-full opacity-0 py-4;
    pointer-events: none;
    transform: translateY(-44px);
    transition: opacity 0.3345s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.15s,
      transform 0.4669s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.108s;
    margin-top: 1px;

    &:nth-child(2)::before,
    &:nth-child(3)::before {
      @apply absolute w-full top-0 bg-gray-300;
      content: '';
      height: 1px;
    }
  }
}

.avatar-size {
  width: 38px;
  height: 38px;
  @screen md {
    width: 44px;
    height: 44px;
  }
}

.nuxt-link-exact-active {
  @apply text-black;

  &:hover,
  &:focus,
  &:active {
    @apply text-black;
  }
}

.bg-blured {
  background-color: rgba(249, 249, 249, 0.4);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
}
</style>
