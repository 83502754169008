/**
 *
 * State
 *
 */
export const state = () => ({});

/**
 *
 * Getters
 *
 */
export const getters = {};

/**
 *
 * Mutation
 *
 */
export const mutations = {};

/**
 *
 * Actions
 *
 */
export const actions = {
  /**
   * Update a given address for the current user
   */
  async updateExpert({ commit }, payload) {
    const { id } = payload;
    if (!id) {
      return Promise.reject(new Error('expert.id should be defined'));
    }
    try {
      const updated = await this.$axios.$put(`experts/${id}`, payload);
      delete updated.user;
      // partial update of the expert obj
      let expert = {};
      if (this.$auth.user.expert) {
        expert = { ...this.$auth.user.expert };
      }
      this.$auth.setUser({
        ...this.$auth.user,
        expert: { ...expert, ...updated }
      });
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error.response);
    }
  },

  /**
   * Update a given address for the current user
   */
  async updateTagsExpert({ commit }, payload) {
    const { id } = payload;
    if (!id) {
      return Promise.reject(new Error('expert.id should be defined'));
    }
    try {
      const updated = await this.$axios.$put(`experts/${id}/tags`, payload);
      delete updated.user;
      this.$auth.setUser({ ...this.$auth.user, expert: { ...updated } });
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
};
