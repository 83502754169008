export default ({ app, $gtm }, inject) => {
  if (process.server || !$gtm) {
    return;
  }
  $gtm.init('GTM-P4CN599');

  app.router.afterEach((to, from) => {
    $gtm.push({
      event: 'nuxtRoute',
      pageType: 'PageView',
      pageUrl: to.fullPath,
      routeName: to.name,
      fromUrl: from.fullPath,
      fromRouteName: from.name
    });
  });
};
