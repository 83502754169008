<template>
  <div class="container flex flex-col justify-center items-center pt-20">
    <h1
      v-if="error.statusCode === 404"
      class="text-xl font-medium text-center text-black font-serif"
    >
      Sorry, that page doesn’t exist!
    </h1>
    <h1 v-else class="text-xl font-medium text-center text-black font-serif">
      Oops, small error (?)
    </h1>

    <p class="mt-2 font-medium text-center text-gray-600">
      {{
        error.statusCode === 404
          ? 'Why not try a search to find something else?'
          : `Look like you've found a bug! It's been reported and we'll fix it!`
      }}
    </p>

    <nuxt-link class="button-outline mt-8" to="/">
      Let's go back home
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: [String, Object],
      default: null
    }
  },

  mounted() {
    console.log('error', this.error);
  }
};
</script>
