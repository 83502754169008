export default function({ store, redirect, route }) {
  if (!store.state.auth.loggedIn) {
    return;
  }
  // By default, redirect to the infos page
  return redirect({
    name: 'me-slug',
    params: { slug: 'profile' }
  });
}
